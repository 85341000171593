<template>
  <div>
    <!-- Folders Container Starts -->
    <folders v-if="folder.children !== undefined && folder.children.length > 0" :folders="folder.children" :class="selectedViewToggle" />
    <!-- /Folders Container Ends -->

    <!-- Files Container Starts -->
    <files v-if="folder.medias !== undefined" :folderId="folder.uid" :files="folder.medias" :class="selectedViewToggle" />
    <!-- /Files Container Ends -->
  </div>
</template>

<script>
import store from '@/store'
import { ref, computed, watch, onUnmounted, useAttrs } from '@vue/composition-api'
import Files from '../contents/Files.vue'
import Folders from '../contents/Folders.vue'
export default {
  name: "ShowFolder",
  components: { Folders, Files },
  setup() {
    const folder = computed(() => store.getters['files_manager/folder'])
    const attrs = useAttrs()
    const selectedViewToggle = computed(() => attrs['data-view'] != undefined ? attrs['data-view'] : '')

    onUnmounted(() => store.commit('files_manager/SET_RESOURCE', {}))
    
    return {
      selectedViewToggle,
      folder,
    }
  }
}
</script>

<template>
  <div class="view-container">
    <h6 class="files-section-title mt-2 mb-75">Fichiers</h6>
    <file-card v-for="(file, index) in files" :key="index" class="card file-manager-item file" :folderId="folderId" :file="file" />
    <div class="d-none flex-grow-1 align-items-center no-result mb-3">
      <feather-icon icon="AlertCircleIcon" class="mr-50" /> Pas de fichier
    </div>
  </div>
</template>

<script>
import FileCard from '@/components/file-manager/folders/FileCard.vue'

export default {
  name: "Files",
  components: {
    FileCard,
  },
  props: ['folderId', 'files'],
}
</script>

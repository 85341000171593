<template>
  <div>
    <b-form-checkbox />
    <div class="card-img-top file-logo-wrapper">
      <file-dropdown
        :menusList="dropdownMenu"
        belongsTo="file"
        @trigger-action="handleAction"
        class="dropdown float-right"
      />
      <div class="d-flex align-items-center justify-content-center w-100">
        <b-img :src="require(`@/assets/images/icons/${icon}`)" />
      </div>
    </div>
    <div class="card-body">
      <div class="content-wrapper">
        <p class="card-text file-name mb-0">{{ file.name }}</p>
        <p class="card-text file-size mb-0">{{ formatBytes(file.size) }}</p>
        <p class="card-text file-date">{{ file.date }}</p>
      </div>
      <small class="file-accessed text-muted"
        >Dernier accès: {{ file.accessed }}</small
      >
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import { BFormCheckbox, BImg } from "bootstrap-vue";
import FileDropdown from "@/views/apps/file-manager/FileDropdown.vue";
import { ref, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// set axios request headers
const requestOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};
export default {
  name: "FileCard",
  props: ["folderId", "file"],
  components: {
    BFormCheckbox,
    BImg,
    FileDropdown,
  },
  setup(props) {
    // Use toast
    const toast = useToast();

    const isFetching = ref(false);
    const icon = computed(() => {
      if (props.file.type == "image") return "jpg.png";
      return "doc.png";
    });

    const dropdownMenu = [
      {
        name: "Ouvrir",
        icon: "EyeIcon",
        action: "open",
      },
      // {
      //   name: "Partager",
      //   icon: "Share2Icon",
      // },
      // {
      //   name: 'Info',
      //   icon: 'InfoIcon',
      //   action: 'show-details',
      // },
      {
        name: "Supprimer",
        icon: "TrashIcon",
        action: "delete",
      },
    ];

    const formatBytes = (bytes, decimals = 2) => {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const handleAction = (action) => {
      // open folder
      if (action === "open") {
        openFile();
      }

      if (action === "delete") {
        deleteFile()
      }

      //! handle other actions from folder card dropdown
    };

    const openFile = async () => {
      const uid = props.folderId;
      requestOptions.responseType = 'blob';
      requestOptions.params = {
        file_id: props.file.file_id,
      };

      isFetching.value = true;

      try {
        const response = await axios.get(`/files-manager/access-file/folders/${uid}`, requestOptions);
        
        // Create a URL for the blob data
        let fileUrl = window.URL.createObjectURL(response.data);
        let fileLink = document.createElement('a');
        
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', props.file.name);
        document.body.appendChild(fileLink);
        fileLink.click();
        
        // Clean up the URL object
        URL.revokeObjectURL(fileUrl);
      } catch ({name, message }) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue lors du téléchargement du fichier',
            icon: "AlertCircleIcon",
            variant: "warning",
          },
        });
      } finally {
        isFetching.value = false;
      }
    };

    const deleteFile = async () => {
      const fileId = props.file?.file_id;

      isFetching.value = true;

      try {
        await axios.delete(`/files-manager/medias/${fileId}`, requestOptions).then(response => {

          toast({
            component: ToastificationContent,
            props: {
              title: response.data?.message,
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }).then(() => {
          let params = { id: props.folderId };
          store.dispatch("files_manager/get", params)
        });
      } catch ({name, message }) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue lors de la suppression du fichier',
            icon: "AlertCircleIcon",
            variant: "warning",
          },
        });
      } finally {
        isFetching.value = false;
      }
    };

    return {
      icon,
      formatBytes,
      dropdownMenu,

      handleAction,
    };
  },
};
</script>
